@import '@shared/styles/colors.css';
@import '@shared/styles/typography.css';

:root {
  --primary: #4318ff; /* 유일하게 고객사별로 변경 가능한 값 */
  --primary10: #ece8ff; /* 유일하게 고객사별로 변경 가능한 값 */
  --kakao-yellow: #fee500;
  --transition-color: 'color, background-color, border-color, text-decoration-color, fill, stroke';
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: var(--gray800);
  letter-spacing: -0.4px;
  background-color: var(--white);
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */
}

#__next {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
p,
pre {
  margin: 0;
}

button {
  padding: 0;
  color: var(--gray800);
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  transition-duration: 0.2s;
  transition-property: var(--transition-color);
}

ul,
ol {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

::selection {
  color: var(--gray800);
  background-color: var(--primary10);
}

::-moz-selection {
  color: var(--gray800);
  background-color: var(--primary10);
}

.ReactModal__Overlay {
  transform: translate(100%, 0);
}

.ReactModal__Overlay--after-open {
  transform: translate(0, 0);
}

.ReactModal__Overlay--before-close {
  transform: translate(100%, 0);
}

.oksoon-icon {
  display: inline-flex;
}
